var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Setup-Step',{attrs:{"customizeTo":_vm.stage == 0 ? { name: 'customers' } : null,"loadingMsg":_vm.loadingMsg,"settings":_vm.settings,"stage":_vm.stage,"step":"customers","title":"Customers"},on:{"yes":function (f) { return _vm.addNew(null, 1); },"no":_vm.next,"update:stage":function($event){_vm.stage=$event}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.stage),callback:function ($$v) {_vm.stage=$$v},expression:"stage"}},[_c('v-tab-item',[_c('BT-List-Endless',{attrs:{"canSearch":false,"loadingMsg":_vm.loadingMsg,"maxHeight":"60vh","navigation":"customers","refreshToggle":_vm.refreshList},on:{"update:loadingMsg":function($event){_vm.loadingMsg=$event},"update:loading-msg":function($event){_vm.loadingMsg=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.buyer.companyName))])],1)]}}])}),_c('p',{staticClass:"my-4"},[_vm._v("Would you like to add a customer?")])],1),_c('v-tab-item',[_c('div',[_c('BT-Btn',{attrs:{"icon":"mdi-plus","label":"Create"},on:{"click":function($event){return _vm.addNew(null, 2)}}}),(!_vm.isLengthyArray(_vm.newItems))?_c('BT-File-Select',{attrs:{"icon":"mdi-file-delimited","label":"Import CSV"},on:{"fileSelected":_vm.importCustomers}}):_c('BT-Btn',{attrs:{"icon":"mdi-file-delimited","label":"Import CSV"},on:{"click":function($event){_vm.stage += 2}}}),_c('BT-Btn',{attrs:{"icon":"mdi-download-network","label":"Import From Service"},on:{"click":function($event){_vm.showServices = true}}})],1),_c('div',[_c('BT-Btn',{attrs:{"buttonClass":"primary--text text-body-2","inline":"","label":"Download CSV Template","text":""},on:{"click":_vm.generateCustomerCSVFile}})],1),(_vm.showServices)?_c('div',[_c('v-divider',{staticClass:"my-2"}),_c('v-subheader',[_vm._v("External Services")]),_c('External-Parties-Blade',{attrs:{"inline":"","small":"","syncNavigation":"product-syncing"},on:{"selected":_vm.selectService}})],1):_vm._e()]),_c('v-tab-item',[_c('BT-List-Endless',{attrs:{"canSearch":false,"items":_vm.newItems,"maxHeight":"60vh"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.companyName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toLocationLine")(item)))])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.addNew(item, 1)}}},[_vm._v("Save")])],1)],1)]}}])})],1),_c('v-tab-item',[_c('BT-Field-String',{attrs:{"label":"Company Name","isEditing":""},on:{"change":_vm.companyNameIsLegit},model:{value:(_vm.newItem.companyName),callback:function ($$v) {_vm.$set(_vm.newItem, "companyName", $$v)},expression:"newItem.companyName"}}),(_vm.newItem.companyName != null && !_vm.isLegit)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v("A customer with this name already exists.")]):_vm._e(),_c('BT-Field-String',{attrs:{"label":"Email Address","isEditing":""},model:{value:(_vm.newItem.email),callback:function ($$v) {_vm.$set(_vm.newItem, "email", $$v)},expression:"newItem.email"}}),_c('BT-Field-String',{attrs:{"label":"Phone Number","isEditing":""},model:{value:(_vm.newItem.phoneNumber),callback:function ($$v) {_vm.$set(_vm.newItem, "phoneNumber", $$v)},expression:"newItem.phoneNumber"}}),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1","disabled":_vm.newItem.companyName == null || _vm.newItem.email == null || !_vm.isLegit,"inline":"","label":"Next"},on:{"click":function($event){_vm.stage += 1}}})],1),_c('v-tab-item',[_c('BT-Address-Auto-Complete',{attrs:{"id":"customer-setup-address","classname":"my-4 mx-auto"},model:{value:(_vm.newItem),callback:function ($$v) {_vm.newItem=$$v},expression:"newItem"}}),_c('GmapMap',{ref:"cust-setup-map",staticStyle:{"width":"100%","height":"50vh"},attrs:{"center":_vm.center,"zoom":7,"options":{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false }}},[(_vm.newItem != null && _vm.newItem.lat != null && _vm.newItem.lng != null)?_c('gmap-marker',{attrs:{"position":{ lat: _vm.newItem.lat, lng: _vm.newItem.lng }}}):_vm._e()],1),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Next"},on:{"click":function($event){_vm.stage += 1}}})],1),_c('v-tab-item',[_c('p',[_vm._v("Will this customer inherit the settings of a template?")]),_c('BT-Select-List-Box',{attrs:{"height":"50vh","isEditing":"","isSelectingNullValue":"","itemText":"templateName","label":"Templates","navigation":"customer-templates"},on:{"change":_vm.save},model:{value:(_vm.newItem.defaultTemplateID),callback:function ($$v) {_vm.$set(_vm.newItem, "defaultTemplateID", $$v)},expression:"newItem.defaultTemplateID"}}),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Done"},on:{"click":_vm.save}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }