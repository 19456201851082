<template>
    <Setup-Step
        @yes="f => addNew(null, 1)"
        @no="next"
        :customizeTo="stage == 0 ? { name: 'customers' } : null"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="customers"
        title="Customers">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="customers"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </BT-List-Endless>

                    <p class="my-4">Would you like to add a customer?</p>
                </v-tab-item>

                <v-tab-item>
                    <div>
                        <BT-Btn
                            icon="mdi-plus"
                            label="Create"
                            @click="addNew(null, 2)" />

                        <BT-File-Select
                            v-if="!isLengthyArray(newItems)"
                            icon="mdi-file-delimited"
                            label="Import CSV"
                            @fileSelected="importCustomers" />
                        <BT-Btn
                            v-else
                            icon="mdi-file-delimited"
                            label="Import CSV"
                            @click="stage += 2" />

                        <BT-Btn
                            icon="mdi-download-network"
                            label="Import From Service"
                            @click="showServices = true" />
                    </div>

                    <div>
                        <BT-Btn
                            @click="generateCustomerCSVFile"
                            buttonClass="primary--text text-body-2" 
                            inline 
                            label="Download CSV Template" 
                            text />
                    </div>

                    <div v-if="showServices">
                        <v-divider class="my-2" />
                        <v-subheader>External Services</v-subheader>
                        <External-Parties-Blade
                            @selected="selectService"
                            inline
                            small
                            syncNavigation="product-syncing" />
                    </div>
                </v-tab-item>

                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :items="newItems"
                        maxHeight="60vh">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.companyName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row no-gutters>
                                    <v-btn small text @click="addNew(item, 1)">Save</v-btn>
                                </v-row>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        @change="companyNameIsLegit"
                        label="Company Name"
                        v-model="newItem.companyName"
                        isEditing />

                    <v-alert v-if="newItem.companyName != null && !isLegit" type="warning">A customer with this name already exists.</v-alert>

                    <BT-Field-String
                        label="Email Address"
                        v-model="newItem.email"
                        isEditing />

                    <BT-Field-String
                        label="Phone Number"
                        v-model="newItem.phoneNumber"
                        isEditing />

                    <BT-Btn 
                        buttonClass="primary mx-1"
                        :disabled="newItem.companyName == null || newItem.email == null || !isLegit"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <!-- <v-tab-item>


                    <BT-Field-String
                        label="Address Line One"
                        v-model="newItem.addressLineOne"
                        isEditing />

                    <BT-Field-String
                        label="Street Number"
                        v-model="newItem.streetNumber"
                        isEditing />

                    <BT-Field-String
                        label="Street Name"
                        v-model="newItem.streetName"
                        isEditing />

                    <BT-Field-String
                        label="Suburb"
                        v-model="newItem.suburb"
                        isEditing />

                    <BT-Field-String
                        label="State"
                        v-model="newItem.state"
                        isEditing />

                    <BT-Field-String
                        label="Postcode"
                        v-model="newItem.postcode"
                        isEditing />

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="findCoordinates" />
                </v-tab-item> -->
                <v-tab-item>
                    <BT-Address-Auto-Complete
                        id="customer-setup-address"
                        classname="my-4 mx-auto"
                        v-model="newItem" />

                    <GmapMap
                        ref="cust-setup-map"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 50vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">                    
                        <gmap-marker v-if="newItem != null && newItem.lat != null && newItem.lng != null" :position="{ lat: newItem.lat, lng: newItem.lng }" />
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />

                </v-tab-item>

                <v-tab-item>
                    <p>Will this customer inherit the settings of a template?</p>

                    <BT-Select-List-Box
                        @change="save"
                        v-model="newItem.defaultTemplateID"
                        height="50vh"
                        isEditing
                        isSelectingNullValue
                        itemText="templateName"
                        label="Templates"
                        navigation="customer-templates" />

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Done"
                        @click="save" />

                </v-tab-item>
               
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue';
import { toCamelCase } from '~helpers';

export default {
    name: 'Customer-Setup',
    components: {
        BTAddressAutoComplete: () => import('~components/BT-Address-Auto-Complete.vue'),
        BTFileSelect: () => import('~components/BT-File-Select.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        ExternalPartiesBlade: () => import('~home/external-parties/External-Parties-Blade.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            cacheData: new Date().getTime(),
            center: { lat: -38, lng: 144 },
            currentExternalParty: null,
            existingCustomers: [],
            isLegit: null,
            loadingMsg: null,
            newItem: {},
            newItems: [],
            orderingItem: null,
            refreshList: false,
            showServices: false,
            stage: 0
        }
    },
    computed: {
        google: getGoogleMapsAPI
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    },
    async mounted() {
        this.existingCustomers = await this.$BlitzIt.store.getAll('customers', { properties: 'ID,Buyer' });
    },
    methods: {
        addNew(j, stepsNum) {
            console.log('adding');
            console.log(j);
            this.newItem = j || { defaultTemplateID: null };

            this.stage += stepsNum;

            if (j != null && j.companyName != null) {
                this.companyNameIsLegit(j.companyName);
            }
        },
        companyNameIsLegit(companyName) {
            this.isLegit = companyName != null && !this.existingCustomers.some(x => x.buyer.companyName == companyName);
        },
        // async findCoordinates() {
        //     this.loadingMsg = 'Loading Coordinates';
        //     this.stage += 1;

        //     await this.$gmapApiPromiseLazy();
        //     var address = getLocationLine(this.newItem, true);
        //     var request = { address: address };
            
        //     var geo = new this.google.maps.Geocoder();
            
        //     try {
        //         var res = await geo.geocode(request);
        //         res = res.results
        //         var selected = null;
            
        //         if (res.length > 1) {
        //             selected = await this.$selectItemFrom({
        //                 items: res,
        //                 itemText: 'formatted_address',
        //                 required: true
        //             });
        //         }
        //         else if (res.length == 1) {
        //             selected = res[0];
        //         }

        //         if (selected != null) {
        //             this.newItem.lat = selected.geometry.location.lat();
        //             this.newItem.lng = selected.geometry.location.lng();
        //         }
        //     }
        //     finally {
        //         this.loadingMsg = null;
        //     }
        // },
        async importCustomers(file) {
            if (!this.isLengthyArray(this.newItems)) {
                this.newItems = await this.importCustomersFromFile(file);
            }

            var customers = await this.$BlitzIt.store.getAll('customers', { properties: 'ID,Buyer' });

            this.newItems = this.newItems.filter(x => !customers.some(p => p.buyer.companyName == x.companyName));

            this.stage += 1;
        },
        next(nextFunc) {
            nextFunc();
        },
        
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.api.post('new-customers', { subscriptionCode: 'CUSTFREE', companies: [this.newItem] }, null, '/PostCustomers');
                    this.existingCustomers.push({
                        id: null,
                        buyer: { companyName: this.newItem.companyName }
                    });

                    var nInd = this.newItems.findIndex(x => x.companyName == this.newItem.companyName);
                    if (nInd >= 0) {
                        this.newItems.splice(nInd, 1);
                    }
                }
                else {
                    //res = await this.$BlitzIt.store.patch('products', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                if (this.isLengthyArray(this.newItems)) {
                    this.stage = 2;
                }
                else {
                    await this.twiddleThumbs(2000)
                    this.refreshList = !this.refreshList;
                    this.stage = 0;
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectService(externalObj) {
            if (externalObj != null && externalObj.credential != null && externalObj.credential.isConnected) {
                this.currentExternalParty = externalObj;
                try {
                    this.loadingMsg = `Searching ${externalObj.optionName}`;
                    var syncRes = await this.$BlitzIt.api.getAll('customer-syncing', { partyID: externalObj.id, onlyUnsynced: true });
                    var convertFunc = this.$BlitzIt.navigation.findConvertFunc('customer-syncing', externalObj.id);
                    if (convertFunc != null) {
                        this.newItems = JSON.parse(syncRes.data.data, toCamelCase).map(x => convertFunc(x));
                    }
                    else {
                        this.newItems = JSON.parse(syncRes.data.data, toCamelCase);
                    }
                    
                    this.stage += 1;
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        }
    }
}
</script>